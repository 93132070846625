// @flow strict
import React from 'react';
import styles from './Meta.module.scss';

type Props = {
  date: string,
  readingTime: string
};

const Meta = ({ date, readingTime }: Props) => (
  <div className={styles['meta']}>
    <span className={styles['meta__date']}>{new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</span>
    <span>&nbsp; • &nbsp;</span>
    <span className={styles['meta__readingTime']}>{readingTime}</span>
  </div>
);

export default Meta;
